import { Button, message, Space, Switch, Table, Typography } from "antd";
import type { TableColumnsType } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { useEffect, useState } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
// import useToken from "antd/es/theme/useToken";
import config from "src/config";
import { RollbackOutlined, DownloadOutlined, WarningOutlined } from "@ant-design/icons";
import useFetch from "../use-fetch";
import MasterUpload from "./MasterUpload";
import MasterDownload from "./MasterDownload";
import FileSaver from "file-saver";
import CleanupButton from "./CleanupButton";
interface HistoryItem {
  action: string;
  backup_file?: string | null;
  id: number;
  result?: { [key: string]: number } | null;
  t: string;
  upload_file?: string | null;
  user?: {
    displayName?: string;
    givenName?: string;
    id?: string;
    jobTitle?: string | null;
    mail?: string | null;
    mobilePhone?: string | null;
    officeLocation?: string | null;
    preferredLanguage?: string | null;
    surname?: string | null;
    userPrincipalName?: string | null;
  } | null;
}

const responseHandler = (r: Response) => {
  if (!r.ok || r.status >= 300) {
    throw new Error(r.statusText);
  }
  return r.json();
};

const rollback = (id: number) =>
  fetch(`${config.api}/history/rollback/${id}`)
    .then(responseHandler)
    .then((result) => message.success(result.message))
    .catch((error) => message.error(error.toString()));

const clear = () =>
  fetch(`${config.api}/history/clear`, { method: "DELETE" })
    .then(responseHandler)
    .then((result) => message.success(result.message))
    .catch((error) => message.error(error.toString()));

const download = (url: string, filename: string) =>
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      var file = new File([blob], filename, { type: "application/x-sqlite3" });
      FileSaver.saveAs(file);
    })
    .catch((error) => message.error(error.toString()));

const lock = () => fetch(`${config.api}/api/lock/on`, { method: "GET" })
  .then(responseHandler)
  .then((result) => message.success(result.message))
  .catch((error) => message.error(error.toString()));

const unlock = () => fetch(`${config.api}/api/lock/off`, { method: "GET" })
  .then(responseHandler)
  .then((result) => message.success(result.message))
  .catch((error) => message.error(error.toString()));

const getLockStatus = () => fetch(`${config.api}/api/lock/status`, { method: "GET" })
  .then(responseHandler)
  .then((result) => result.locked)
  .catch((error) => message.error(error.toString()));


const History: React.FC<{ size: SizeType }> = ({ size }) => {
  const { data, error, loading, fetchData } = useFetch("/history/");
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    getLockStatus().then(setIsLocked);
  }, [])
  // const designToken = useToken()[1];

  const colums: TableColumnsType<HistoryItem> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    { title: "Action", dataIndex: "action", key: "action" },
    {
      title: "User",
      key: "user",
      render: (_, record) => (
        <Space size="middle">{record.user && <Typography.Text>{record.user.userPrincipalName}</Typography.Text>}</Space>
      ),
    },
    { title: "Date", dataIndex: "t", key: "t" },
    {
      title: "Update",
      key: "upload_file",
      render: (_, record) => (
        <Space size="middle">
          {record.upload_file && (
            <Button
              icon={<DownloadOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                download(`${config.api}/history/download/upload_file/${record.id}`, String(record.upload_file));
              }}
            >
              {record.upload_file}
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: "Backup",
      key: "backup_file",
      render: (_, record) => (
        <Space size="middle">
          {record.backup_file && (
            <Button
              icon={<DownloadOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                download(`${config.api}/history/download/backup_file/${record.id}`, String(record.backup_file));
              }}
            >
              {record.backup_file}
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          {record.backup_file && (
            <Button icon={<RollbackOutlined />} onClick={() => rollback(record.id).then(() => fetchData())}>
              RollBack
            </Button>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  const changeLock = (checked: boolean) => {
    if (checked) {
      unlock().then(() => getLockStatus()).then(setIsLocked)
    } else {
      lock().then(() => getLockStatus()).then(setIsLocked)
    }
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }} className="gerl-customer-table">
      <Space>
        <MasterDownload size={size} title="Download Master" />
        <MasterUpload size={size} title="Update Master" onUpdated={() => fetchData()} />
        <CleanupButton size={size} onCleanupFinished={() => fetchData()} />

        <Switch size={"default"} checkedChildren="API on" unCheckedChildren="API off" checked={!isLocked} onChange={changeLock} />

      </Space>
      <Typography.Title level={4}>History</Typography.Title>
      <Table
        loading={loading}
        size={size}
        columns={colums}
        rowKey={(record) => record.id}
        dataSource={data}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <Table
              size="small"
              columns={[
                { title: "", dataIndex: "key" },
                { title: "", dataIndex: "value" },
              ]}
              dataSource={Object.keys(record.result).map((key) => ({ key, value: record.result[key] }))}
            />
          ),
          rowExpandable: (record) => record.result && Object.keys(record.result).length > 0,
        }}
        footer={() => (
          <Button danger type="primary" icon={<WarningOutlined />} onClick={() => clear().then(() => fetchData())}>
            Delete all
          </Button>
        )}
      />
    </Space>
  );
};

export default History;
