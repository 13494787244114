// https://fluentsite.z22.web.core.windows.net/quick-start
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import { TeamsFxContext } from "./Context";
import config from "./lib/config";
import { ToolOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps, theme as antTheme, } from 'antd';
import React, { useState } from "react";
import SSOPerform from './iot/SSOPerform';

const { Content, Sider } = Layout;



const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};

function AppMenuLeft() {
  const location = useLocation()
  const navigate = useNavigate();
  // useEffect(() => {console.log(location)}, [location])

  const clickHandler: MenuProps['onClick'] = ({ item, key, keyPath, domEvent }) => navigate(key);

  const items = [/*{
    key: `/tab`,
    icon: <BulbOutlined />,
    label: `Geräte`,
    onClick: clickHandler
  },*/
    {
      key: `tools_group`,
      icon: <ToolOutlined />,
      label: `Tools`,
      children: [
        {
          key: `/tools`,
          label: `Benning Tester`,
          onClick: clickHandler
        }
      ]
    }];

  return <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]} items={items} />
}

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential, inTeams, error } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = antTheme.useToken();

  const [siderCollapsed, setSiderCollapsed] = useState(false)


  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
              ? teamsHighContrastTheme
              : teamsLightTheme
        }
        style={{ background: tokens.colorNeutralBackground2 }}
      >
        <Router>
          <Layout>
            <Sider
              style={siderStyle}
              breakpoint="lg"
              onBreakpoint={(broken) => {
                console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                setSiderCollapsed(collapsed)
              }}
            >
              <AppMenuLeft />

            </Sider>
            <Layout style={{ height: '100vh', marginInlineStart: siderCollapsed ? 80 : 200, background: tokens.colorNeutralBackground2 }}>
              <Content style={{ margin: '24px 16px', overflow: 'initial' }}>

                {!inTeams ? (
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <strong>Achtung</strong>
                    <span>Diese App funktioniert nur in Microsoft Teams.</span>
                  </div>
                ) : (
                  loading ? (
                    <Spinner style={{ margin: 100 }} />
                  ) : (
                    <Routes>
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/termsofuse" element={<TermsOfUse />} />
                      <Route path="/tab" element={<Tab><SSOPerform size={'middle'} onSuccessNavigateTo={'/tools'} /></Tab>} />
                      {/*<Route path="/tab" element={<Tab><DeviceStatusTable size={'middle'} /></Tab>} />*/}
                      <Route path="/tools" element={<Tab><gerl-benning-tester-hub size="middle" routing="off"></gerl-benning-tester-hub></Tab>} />
                      <Route path="*" element={<Navigate to={"/tab"} />}></Route>
                    </Routes>
                  )
                )}
              </Content>
            </Layout>
          </Layout>
        </Router>

      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
