// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import { Button, message } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import config from "src/config";
import FileSaver from "file-saver";
import { useState } from "preact/hooks";

const responseHandler = (r: Response) => {
  if (!r.ok || r.status >= 300) {
    throw new Error(r.statusText);
  }
  return r.json();
};

const doCleanup = () =>
  fetch(`${config.api}/api/cleanup`, {
    method: 'GET',
  })
    .then(responseHandler)
    .catch(err => {
      message.error(err.toString())
    })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CleanupButton: React.FC<{ size: SizeType; title?: string; onCleanupFinished?: () => void }> = ({ size, title, onCleanupFinished }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      loading={loading}
      disabled={loading}
      onClick={(e) => {
        e.stopPropagation();
        setLoading(true);
        doCleanup().finally(() => {
          setLoading(false);
          if (onCleanupFinished) {
            onCleanupFinished();
          }
        })
      }}
      size={size}
      type="primary"
      icon={<ClearOutlined />}
    >
      {" "}
      {title ? title : "Cleanup"}
    </Button>
  );
};

export default CleanupButton;
