import { Typography, Space, ConfigProvider, Spin, Alert, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { signOn } from "./core";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { TeamsFxContext } from "../Context";
import { useData } from "@microsoft/teamsfx-react";
import config from "../lib/config";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const SSOPerform: React.FC<{ size: SizeType; onSuccessNavigateTo: string }> = ({ size, onSuccessNavigateTo }) => {
  const _size = size
  const navigate = useNavigate();

  const [needConsent, setNeedConsent] = useState(false);
  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
  const { loading, data: authSucceed, error, reload } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (needConsent) {
      await teamsUserCredential!.login(["User.Read"]);
      setNeedConsent(false);
    }
    try {
      const token = (await teamsUserCredential.getToken(""))!.token;
      const authSucceeded = await signOn(token)
      return authSucceeded
    } catch (error: any) {
      if (error.message.includes("The application may not be authorized.")) {
        setNeedConsent(true);
      } else {
        throw new Error("SignOn konnte nicht ausgeführt werden.")
      }
    }
  });

  useEffect(() => {
    if (config.isDevEnv === true || authSucceed) {
      if (onSuccessNavigateTo) {
        navigate(onSuccessNavigateTo)
      }
    }
  }, [authSucceed]);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#183146" } }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        {config.isDevEnv === false && (error || needConsent) ? <Alert
          message="Authentifizierung fehlgeschlagen"
          showIcon
          description="Sie konnten nicht ordnungsgemäß angemeldet werden"
          type="error"
          action={
            <Button size="small" onClick={reload}>
              Noch einmal versuchen
            </Button>
          }
        /> :
          <Spin spinning={loading}>
            {authSucceed ?
              <Text>Sie wurden angemeldet.</Text>
              :
              <Text>Sie werden angemeldet..</Text>
            }
          </Spin>}
      </Space>
    </ConfigProvider>
  );
};

export default SSOPerform;
